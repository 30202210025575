<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { putAction, getAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'

export default {
  name: 'distributionSet',
  data() {
    return {
      ...api.command.getState(),
      detail: {
       
      },
      shopDataList: [],
      priceTable1: [
        {
          type: '推广佣金',
          price: 0,
        },
        {
          type: '下级订单佣金',
          price: 0,
        },
      ],
      priceTable2: [
        {
          type: '推广佣金',
          price1: 0,
          price2: 0,
        },
        {
          type: '下级订单佣金',
          price1: 0,
          price2: 0,
        },
      ],
    }
  },
  watch: {},
  mounted() {
    // 获取详情
    api.command.getDetail.call(this, { url: `/farmSalesConfig/detail` }).then((result) => {
      if (result) {
        this.priceTable1[0].price = result.extendRate
        this.priceTable1[1].price = result.orderRate
        this.priceTable2[0].price1 = result.extendUseAmount
        this.priceTable2[0].price2 = result.extendGiveAmount
        this.priceTable2[1].price1 = result.orderUseAmount
        this.priceTable2[1].price2 = result.orderGiveAmount
        this.$forceUpdate()
      }
    })
  },
  methods: {
    async onShopSearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmShopSubMerchant/selectSubMerList?subMerName=${value ?? ''}`,
      })
      this.shopDataList = searchResult.map((el) => {
        return {
          name: el.subMerName,
          value: el.id,
        }
      })
    },
    getForm1() {
      return {
        title: '分销设置',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '分销员加入条件',
            type: 'radioButton',
            key: 'joinConditions',
            cols: 14,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            typeData: [
              /* {
                name: '有条件',
                value: '0'
              }, */
              {
                name: '无条件',
                value: '1',
              },
            ],
          },
          {
            display: this.detail?.joinConditions == '0',
            type: 'row',
            cols: 14,
            gutter: 6,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            children: [
              {
                type: 'text',
                value: ' ',
                cols: 4,
              },
              {
                type: 'radioArea',
                key: 'text2',
                cols: 14,
                typeData: [
                  {
                    name: () => {
                      return <span style="margin-right: 100px">需购买指定商品&nbsp;</span>
                    },
                    value: '0',
                  },
                  {
                    name: () => {
                      const onInput = (data) => {
                        this.detail.text2value2 = data.target.value
                      }
                      return (
                        <span style="margin-right: 100px">
                          自购金额满&nbsp;
                          <a-input
                            disabled={this.detail.text2 !== '1'}
                            style={{ width: '70px' }}
                            type="number"
                            min={0}
                            placeholder=""
                            onChange={onInput}
                            value={this.detail.text2value2}
                          />
                          &nbsp;元
                        </span>
                      )
                    },
                    value: '1',
                  },
                  {
                    name: () => {
                      const onInput = (data) => {
                        this.detail.text2value3 = data.target.value
                      }
                      return (
                        <span style="margin-right: 100px">
                          消费笔数满&nbsp;
                          <a-input
                            disabled={this.detail.text2 !== '2'}
                            style={{ width: '70px' }}
                            type="number"
                            min={0}
                            placeholder=""
                            onChange={onInput}
                            value={this.detail.text2value3}
                          />
                          &nbsp;笔
                        </span>
                      )
                    },
                    value: '2',
                  },
                ],
              },
            ],
          },
          /* {
            display: true,
            name: '申请填写信息',
            type: 'checkButton',
            key: 'text3',
            cols: 14,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
            typeData: [
              {
                name: '全部',
                value: '0'
              },
              {
                name: '姓名',
                value: '1'
              },
              {
                name: '手机号',
                value: '2'
              },
              {
                name: '身份证',
                value: '3'
              },
              {
                name: '地址',
                value: '4'
              },
            ],
          }, */
          {
            display: true,
            name: '平台审核方式',
            type: 'radioButton',
            key: 'auditMethod',
            cols: 14,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            typeData: [
              {
                name: '人工审核',
                value: '0',
              },
              // {
              //   name: '无需审核，条件满足自动通过',
              //   value: '1',
              // },
            ],
          },
          {
            display: true,
            name: '绑定关系',
            type: 'radioButton',
            key: 'bindRelation',
            cols: 14,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            typeData: [
              {
                name: '邀请码制',
                value: '0',
              },
              /* {
                name: '首次点击分享链接',
                value: '1'
              },
              {
                name: '首次购买',
                value: '2'
              }, */
            ],
          },
          {
            display: true,
            name: '邀请下级',
            type: 'radioButton',
            key: 'inviteReward',
            cols: 14,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            typeData: [
              {
                name: '无奖励',
                value: '0',
              },
              {
                name: '有奖励',
                value: '1',
              },
            ],
          },
          {
            display: this.detail?.inviteReward == '1',
            type: 'row',
            cols: 14,
            gutter: 6,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            children: [
              {
                type: 'text',
                value: ' ',
                cols: 3,
              },
              {
                type: 'text',
                value: '每拉一个下级，奖励',
                cols: 4,
              },
              {
                label: ' ',
                type: 'inputNumber',
                cols: 3,
                key: 'rewardAmount',
                props: {
                  min: 0,
                },
              },
              {
                type: 'text',
                value: '元',
                cols: 4,
              },
            ],
          },
          {
            display: true,
            name: '商品佣金设置',
            type: 'radioButton',
            key: 'commissionSetting',
            cols: 14,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            typeData: [
              {
                name: '基于商品设置佣金',
                value: '0',
              },
              {
                name: '基于价格设置佣金',
                value: '1',
                hint: '例：小明消费26.3元，获得佣金2元',
              },
            ],
          },
          {
            display: true,
            type: 'row',
            cols: 14,
            gutter: 6,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            children: [
              {
                display: true,
                type: 'text',
                value: ' ',
                cols: 3,
              },
              {
                display: this.detail?.commissionSetting == '0',
                cols: 14,
                type: 'table',
                showFootButton: false,
                showRowSelect: false,
                showPagination: false,
                bordered: true,
                styles: {
                  width: '400px',
                },
                columns: [
                  {
                    dataIndex: 'type',
                    align: 'center',
                    title: '类型',
                    width: '30%',
                  },
                  {
                    dataIndex: 'price',
                    align: 'center',
                    title: '佣金比例',
                    width: '70%',
                    customRender: function (text, records) {
                      const onInput = (data) => {
                        if (data.target.value) {
                          if (!/[0-9\.]/.test(data.target.value)) {
                            data.target.value = null
                            that.$message.warning('请输入数字！')
                            return false
                          } else {
                            records.price = data.target.value
                          }
                        } else {
                          records.price = 0
                        }
                      }
                      return (
                        <div>
                          <a-input
                            type="number"
                            onChange={onInput}
                            value={text}
                            placeholder=""
                            style="width: 80px;marginRight: 4px"
                          />
                          %
                        </div>
                      )
                    },
                  },
                ],
                dataSource: this.priceTable1,
              },
              {
                display: this.detail.commissionSetting == '1',
                cols: 14,
                type: 'table',
                showFootButton: false,
                showRowSelect: false,
                showPagination: false,
                bordered: true,
                styles: {
                  width: '600px',
                },
                columns: [
                  {
                    dataIndex: 'type',
                    align: 'center',
                    title: '类型',
                    width: '20%',
                  },
                  {
                    dataIndex: 'price',
                    align: 'center',
                    title: '佣金价格',
                    width: '80%',
                    customRender: function (text, records) {
                      const onInput = (data) => {
                        if (data.target.value) {
                          if (!/[0-9\.]/.test(data.target.value)) {
                            data.target.value = null
                            that.$message.warning('请输入数字！')
                            return false
                          } else {
                            records.price1 = data.target.value
                          }
                        } else {
                          records.price1 = 0
                        }
                      }
                      const onInput2 = (data) => {
                        if (data.target.value) {
                          if (!/[0-9\.]/.test(data.target.value)) {
                            data.target.value = null
                            that.$message.warning('请输入数字！')
                            return false
                          } else {
                            records.price2 = data.target.value
                          }
                        } else {
                          records.price2 = 0
                        }
                      }
                      return (
                        <div>
                          每消费
                          <a-input
                            type="number"
                            onChange={onInput}
                            placeholder=""
                            value={records.price1}
                            style="width: 80px;margin: 0 4px"
                          />
                          元，获得佣金
                          <a-input
                            type="number"
                            onChange={onInput2}
                            placeholder=""
                            value={records.price2}
                            style="width: 80px;margin: 0 4px"
                          />
                          元
                        </div>
                      )
                    },
                  },
                ],
                dataSource: this.priceTable2,
              },
            ].filter((e) => e.display),
          },
        ].filter((e) => e.display),
      }
    },
    getForm2() {
      return {
        title: '结算设置',
        type: 'cardForm',
        data: [
          {
            name: '结算时间',
            type: 'radioButton',
            key: 'settlementTime',
            cols: 14,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            typeData: [
              {
                name: '订单收货后的第15天结算',
                value: '0',
                hint: '当前订单客户无法再发起退款，可避免订单退款但佣金已结算的亏损。自动结算时间一般为7+15天。',
              },
              {
                name: '订单确认收货，立即结算',
                value: '1',
                hint: '风险提示：可能会产生订单退款但佣金已发放的亏损 。',
              },
            ],
          },
          {
            name: '分销结算二级商户',
            type: 'dropDownInput',
            key: 'subMerName',
            cols: 14,
            labelCol: { span: 4 },
            wrapperCol: { span: 15 },
            onInputSearch: this.onShopSearch,
            typeData: this.shopDataList,
            valueKey: 'subMerId',
            inputProps: {
              addonBefore: <a-icon type="search" />,
            },
          },
          // {
          //   name: '分销员自己下单',
          //   type: 'radioButton',
          //   key: 'oneselfBuy',
          //   cols: 14,
          //   labelCol: { span: 3 },
          //   wrapperCol: { span: 21 },
          //   typeData: [
          //     {
          //       name: '无佣金',
          //       value: '0',
          //     },
          //     {
          //       name: '佣金给分销员自己',
          //       value: '1',
          //     },
          //   ],
          // },
        ],
      }
    },
    submit(data) {
      // ;[('extendRate', 'orderRate', 'extendUseAmount', 'extendGiveAmount', 'orderUseAmount', 'orderGiveAmount')].map(
      //   (e) => (data[e] = '')
      // )
      if (data.commissionSetting === '0') {
        data.extendRate = this.priceTable1[0].price
        data.orderRate = this.priceTable1[1].price
      } else {
        data.extendUseAmount = this.priceTable2[0].price1
        data.extendGiveAmount = this.priceTable2[0].price2
        data.orderUseAmount = this.priceTable2[1].price1
        data.orderGiveAmount = this.priceTable2[1].price2
      }

      putAction('/farmSalesConfig/update', data).then((result) => {
        if (result.code === 200) {
          this.$router.push('/marketingCenter/distributionManage')
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          sumbit: true,
          onClick: this.submit,
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/marketingCenter/distributionManage')
          },
        },
      ]
      return {
        left,
      }
    },
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.detail} data={[this.getForm1(), this.getForm2()]} />
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}

/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
